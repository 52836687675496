import React from "react";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const SectionFive = () => {
    const navigate = useNavigate();
  return (
    <div className="mb-5 pb-5 overflow-hidden">
      <Col lg={7} className="mx-auto text-center overflow-hidden">
        <p className="mx-2 px-2 mb-5 display-5 fw-normal">
          The Reasons to Choose Us as <br /> Your <strong>Enterprise Partner</strong>
        </p>
        <Button onClick={() => navigate('/contact')}  className="gradient-border-button-learn">Learn More</Button>
      </Col>
    </div>
  );
};

export default SectionFive;
