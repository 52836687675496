

import React, { useState } from "react";
import "./../ContactUs.css"; // Import the CSS file for styling
import "./../App.css";
import backgroundImage from "../assets/img/MapImage.png"; // Update with the correct image path

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    alert("Thank you for contacting us!");
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <div className="contact-section" style={{ width: "100%" }}>
      <div
        className="image-section"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: `linear-gradient(90deg, #050912 0.1%, #0D121E 33.37%, #0D121E 66.63%, #050912 99.9%)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "50vh", // Responsive height
          width: "100%",
          position: "relative",
        }}
      >
        <h2
          className="title-text"
          style={{
            position: "absolute",
            bottom: "10px", // Slightly away from bottom for mobile
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            zIndex: 2,
            fontSize: "2.5em", // Responsive font size
            textAlign: "center", // Center text for all sizes
            padding: "0 20px", // Add padding for smaller screens
          }}
        >
          Get in Touch
        </h2>
      </div>

      <div
        className="form-container"
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
          paddingLeft: "10%",
          paddingRight: "10%",
          background:
            "linear-gradient(90deg, #050912 0.24%, #0D121E 50.01%, #050912 99.79%)",
        }}
      >
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label style={{ color: "white" }} htmlFor="name">
                  Your Name
                </label>
                <input
                  style={{
                    borderRadius: "12px",
                    border: "1px dashed rgba(190, 190, 190, 0.50)",
                    background: "#0E1422",
                    color: "white",
                    width: "100%", // Full width for responsiveness
                    padding: "12px",
                  }}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Your Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label style={{ color: "white" }} htmlFor="email">
                  Your Email
                </label>
                <input
                  style={{
                    borderRadius: "12px",
                    border: "1px dashed rgba(190, 190, 190, 0.50)",
                    background: "#0E1422",
                    color: "white",
                    width: "100%",
                    padding: "12px",
                  }}
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group full-width">
                <label style={{ color: "white" }} htmlFor="subject">
                  Your Message Subject
                </label>
                <input
                  style={{
                    borderRadius: "12px",
                    border: "1px dashed rgba(190, 190, 190, 0.50)",
                    background: "#0E1422",
                    color: "white",
                    width: "100%",
                    padding: "12px",
                  }}
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder="Enter Your Message Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group full-width">
                <label style={{ color: "white" }} htmlFor="message">
                  Your Message
                </label>
                <textarea
                  style={{
                    borderRadius: "12px",
                    border: "1px dashed rgba(190, 190, 190, 0.50)",
                    background: "#0E1422",
                    color: "white",
                    width: "100%",
                    padding: "12px",
                    minHeight: "150px", // Ensures enough space for mobile typing
                  }}
                  id="message"
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row submit-row">
              <button
                className="navbar-contact-us-btn btn btn-primary"
                type="submit"
                style={{
                  width: "100%", // Full-width button for smaller screens
                  padding: "12px",
                  fontSize: "1em",
                }}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
