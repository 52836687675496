import React from "react";
import { Container, Row, Button, Image, Col } from "react-bootstrap";
import HeroIllustration from "../assets/img/hero_planet.svg";
import Marquee from "react-fast-marquee";
import Layer12 from "../assets/img/Layer 12.svg";
import Layer10 from "../assets/img/Layer 10.svg";
import Layer14 from "../assets/img/Layer 14.svg";
import Layer16 from "../assets/img/Layer 16.svg";
import Layer2 from "../assets/img/partner11.svg";
import Layer20 from "../assets/img/partner22.svg";
import Layer22 from "../assets/img/partner33.svg";
import Layer24 from "../assets/img/partner44.svg";
import Layer33 from "../assets/img/partner6.svg";
import Layer40 from "../assets/img/Group 40.svg";
import Layer42 from "../assets/img/Group 42.svg";
import linkmobility from "../assets/img/linkmobility.svg";
import "../assets/rotation-planet.css";
import { useNavigate } from "react-router-dom";
import CounterSection from "./Counter";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="hero-container">
      <section id="about" className="hero-section text-center text-white">
        <Container fluid>
          <Row className="align-items-center pb-5">
            <div className="hero-wrapper">
              <Image
                style={{ "z-index": "-1", position: "relative" }}
                src={HeroIllustration}
                className="hero-illustration"
                alt="Hero Illustration"
                fluid
              />
              <div className="hero-content text-center">
                <h1 className="display-4 animated-text">
                  Bringing <span className="animated-word">The World</span>{" "}
                  <br /> to Your Fingertips
                </h1>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    // height: "64px",
                    padding: "0 16px",
                    // gap: "24px",
                    // "border-radius": "1rem",
                    // background: "rgba(5, 9, 18, 0.50)",
                    "margin-top": "3%",
                  }}
                >
                  <div
                    className=" px-4"
                    style={{
                      "border-radius": "1rem",
                      background: "rgba(5, 9, 18, 0.50)",
                    }}
                  >
                    <p className="pt-3 d-inline-block mx-4">
                      Connect with us for Wholesale A2P and Enterprise messaging
                    </p>
                    <Button
                      onClick={() => navigate("/contact")}
                      className="navbar-contact-us-btn btn d-inline-block btn-primary"
                    >
                      Contact Us
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="overlay-row">
            <p>
              We service a portfolio of Telecom, Digital and Enterprise clients
              from different market segments.
            </p>
          </Row>
          <Row
            className="text-center justify-content-center align-items-center logos-partners mt-5 py-5"
            style={{
              background:
                "linear-gradient(90deg, rgba(5, 9, 18, 0.00) 0%, rgba(5, 9, 18, 0.80) 53.5%, rgba(5, 9, 18, 0.00) 100%)",
            }}
          >
            <div className="marquee-width">
              <Marquee speed={200}>
                <Image src={Layer2} alt="Partner 5" fluid className="mx-5" />
                <Image src={Layer20} alt="Partner 6" fluid className="mx-5" />
                <Image src={Layer22} alt="Partner 7" fluid className="mx-5" />
                {/*<Image src={Layer30} alt="Partner 9" fluid className="mx-5"/>*/}
                <Image src={Layer24} alt="Partner 8" fluid className="mx-5" />
                <Image src={linkmobility} alt="Partner 8" fluid className="mx-5" />
                <Image src={Layer33} alt="Partner 10" fluid className="mx-5" />
                <Image src={Layer40} alt="Partner 11" fluid className="mx-5" />

                <Image src={Layer42} alt="Partner 12" fluid className="mx-5" />
                <Image src={Layer10} alt="Partner 1" fluid className="mx-5" />
                <Image src={Layer12} alt="Partner 2" fluid className="mx-5" />
                <Image src={Layer14} alt="Partner 3" fluid className="mx-5" />
                <Image src={Layer16} alt="Partner 4" fluid className="mx-5" />
              </Marquee>
            </div>
          </Row>
          <Row id="about" className="mt-5 py-5">
            <Col lg={8} className="mx-auto text-center">
              <p
                id="about-us"
                className="my-5 mx-5 px-5 display-6 text-mobile-left custom-mobile-padding"
              >
                Unlock the Future of <strong>Business Communication</strong>{" "}
                with Digi-Touch
              </p>
              <div className="custom-border custom-mobile-padding"></div>
              <p className="text-mobile-left">
                Digi-Touch is a trusted A2P aggregation partner for mobile
                operators and enterprises globally, providing managed solutions
                for A2P monetization, SMS filtering, digital and A2P enablement.
                Digi-Touch has no ownership of mobile operations and thus acts
                neutrally as a managed technology service provider. Digi-Touch
                aims to bridge the MNO with the digital world by enabling and
                securing infrastructure monetization on behalf of the mobile
                operator.
              </p>
            </Col>
          </Row>
          <Row
            className="justify-content-center align-items-center flex-nowrap"
            style={{ paddingBottom: "10%" }}
          >
            {/* Column 1: Right aligned */}
            <Col xs="5" className="text-end">
              <div className="d-flex justify-content-end w-100">
                <svg
                  className="d-block mb-1"
                  width="100%"
                  height="auto"
                  viewBox="0 0 301 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="300.97"
                    y="1.28186"
                    width="200"
                    height="1"
                    transform="rotate(-180 300.97 1.28186)"
                    fill="url(#paint0_linear_2144_147729)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2144_147729"
                      x1="300.97"
                      y1="1.78186"
                      x2="600.97"
                      y2="1.78186"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FF3E6C" />
                      <stop offset="1" stopColor="#FF3E6C" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="d-flex justify-content-end w-100">
                <svg
                  className="d-block mb-1"
                  width="100%"
                  height="auto"
                  viewBox="0 0 301 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="300.97"
                    y="1.28186"
                    width="200"
                    height="1"
                    transform="rotate(-180 300.97 1.28186)"
                    fill="url(#paint0_linear_2144_147729)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2144_147729"
                      x1="300.97"
                      y1="1.78186"
                      x2="600.97"
                      y2="1.78186"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FF3E6C" />
                      <stop offset="1" stopColor="#FF3E6C" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Col>

            {/* Column 2: Center aligned */}
            <Col xs="2" className="text-center">
              <svg
                className="responsive-svg"
                width="100%"
                height="auto"
                viewBox="0 0 91 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g className="rotate" transform="translate(50, 50)">
                  <path
                    d="M16.5092 74.5382C18.8523 76.8814 22.6513 76.8814 24.9944 74.5382C27.3376 72.1951 27.3376 68.3961 24.9944 66.0529C22.6513 63.7098 18.8523 63.7098 16.5092 66.0529C14.166 68.3961 14.166 72.1951 16.5092 74.5382Z"
                    fill="#FF3E6C"
                  />
                  <path
                    d="M66.6752 24.3721C69.0183 26.7152 72.8173 26.7152 75.1605 24.3721C77.5036 22.0289 77.5036 18.2299 75.1605 15.8868C72.8173 13.5437 69.0184 13.5437 66.6752 15.8868C64.3321 18.23 64.3321 22.0289 66.6752 24.3721Z"
                    fill="#FF3E6C"
                  />
                </g>
                <path
                  className="static"
                  d="M30.2785 60.7685C38.87 69.3601 52.7996 69.3601 61.3912 60.7685C69.9827 52.177 69.9827 38.2474 61.3912 29.6558C52.7996 21.0643 38.87 21.0643 30.2785 29.6558C21.6869 38.2474 21.6869 52.177 30.2785 60.7685Z"
                  fill="white"
                />
              </svg>
            </Col>

            {/* Column 3: Left aligned */}
            <Col xs="5" className="text-start">
              <div className="d-flex justify-content-start w-100">
                <svg
                  className="d-block mb-1"
                  width="100%"
                  height="auto"
                  viewBox="0 0 301 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.21875"
                    y="0.28186"
                    width="200"
                    height="1"
                    fill="url(#paint0_linear_2144_147727)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2144_147727"
                      x1="0.21875"
                      y1="0.78186"
                      x2="300.219"
                      y2="0.78186"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FF3E6C" />
                      <stop offset="1" stopColor="#FF3E6C" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="d-flex justify-content-start w-100">
                <svg
                  className="d-block mb-1"
                  width="100%"
                  height="auto"
                  viewBox="0 0 301 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.21875"
                    y="0.28186"
                    width="200"
                    height="1"
                    fill="url(#paint0_linear_2144_147727)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2144_147727"
                      x1="0.21875"
                      y1="0.78186"
                      x2="300.219"
                      y2="0.78186"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FF3E6C" />
                      <stop offset="1" stopColor="#FF3E6C" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/*<div className="dots-image">*/}
      {/*    {" "}*/}
      {/*    <Image*/}
      {/*        src={DotsImage}*/}
      {/*        width={"100%"}*/}
      {/*        alt="Dots Illustration"*/}
      {/*        fluid*/}
      {/*        className=""*/}
      {/*    />*/}
      {/*    <Image*/}
      {/*        src={DotsImage}*/}
      {/*        width={"100%"}*/}
      {/*        alt="Dots Illustration"*/}
      {/*        fluid*/}
      {/*        className=""*/}
      {/*    />*/}

      {/*</div>*/}
      <div id="services" className="dot-background">
        <Container className="position-relative stats-container">
          <Row className="d-flex justify-content-around">
            <CounterSection />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
