import React from "react";
import { Col } from "react-bootstrap";

const SectionSeven = () => {
  return (
    <div className=" my-5 py-5 overflow-hidden">
      <Col lg={7} className="mx-auto text-center overflow-hidden">
        <h1 className=" mb-5 fw-bolder">Why Us</h1>
        <p className="mx-5 px-5 mb-5 fs-4 fw-normal  ">
          With its extensive 200 partner network, strong global reach and
          aggregator base, Digi-Touch is a catalyst in aggregating the A2P
          traffic for MNO’s, because of its:
        </p>
      </Col>
    </div>
  );
};

export default SectionSeven;
