import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import FooterLogo from "../assets/img/group-68-2.png";
import VectorImage from "../assets/img/vector-34.svg";
import { useNavigate } from 'react-router-dom';


const Footer = () => {
  const navigate = useNavigate();
  const handleNavClick = (sectionId) => {
    navigate('/');
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0); // delay to ensure navigation happens before scrolling
  };
  return (
    <footer className="footer py-5">
      <Container>
        <Row className=" border-bottom pb-5">
          <Col xs={12} md={6}>
            <div className="mb-3">
              <Image
                src={FooterLogo}
                width={70}
                alt="Footer Logo"
                className=""
              />
            </div>
            <div className="text-footer mb-3">
              United House, 9 Pembridge Road, London, UK
            </div>
            <div className="text-footer mb-3">+44 208 6387649</div>
            <div className="text-footer mb-3">info@digi-touch.com</div>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12} md={4} className="mb-4">
                <div className="h5 fw-bold text-white mb-3">Services</div>
                <div className="text-footer mb-3"><a role={"button"} onClick={() => handleNavClick('services')}>Enterprise Messaging</a></div>
                  <div className="text-footer mb-3"> <a role={"button"} onClick={() => handleNavClick('services')}> Wholesale A2P Messaging</a></div>
                <div className="text-footer mb-3"><a role={"button"} onClick={() => handleNavClick('services')}>Managed Services</a></div>
              </Col>
              <Col xs={12} md={4} className="mb-4">
                <div className="h5 fw-bold text-white mb-3">Products</div>
                <div className="text-footer mb-3"><a role={"button"} onClick={() => handleNavClick('products')}>SMS
                  Firewall </a></div>
                <div className="text-footer mb-3"><a role={"button"} onClick={() => handleNavClick('products')}>Flash
                  Call Firewall</a></div>
                <div className="text-footer mb-3"><a role={"button"} onClick={() => handleNavClick('products')}>Network
                  Probe Testing </a></div>
                <div className="text-footer mb-3"> <a role={"button"} onClick={() => handleNavClick('products')}> MNP
                </a></div>
              </Col>
                <Col xs={12} md={4} className="mb-4">
                    <div className="h5 fw-bold text-white mb-3">Company</div>
                    <div className="text-footer mb-3"><a role={"button"}
                                                         onClick={() => handleNavClick('about')}>Partners </a>
                    </div>
                    <div className="text-footer mb-3"><a role={"button"} onClick={() => handleNavClick('about-us')}>About Us </a>
                    </div>
                    <div className="text-footer mb-3"><a role={"button"} onClick={() => handleNavClick('whyus')}>Why
                        Us </a>
                    </div>
                    <div className="text-footer mb-3"><a role={"button"}
                                                         onClick={() => handleNavClick('careers')}>Careers </a>
                    </div>
                </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col className="text-start col-2">
            <Image src={VectorImage} alt="Vector" className="" />
          </Col>
          <Col className="mx-auto col-8 text-center">
            <p className="text-white">
      <span className="span text-footer">
        © 2024 Digi-Touch. All Rights Reserved.
      </span>
            </p>
          </Col>
          <Col className="text-end text-footer d-none d-md-block col-2">
            London, UK.
          </Col>
        </Row>


      </Container>
    </footer>
  );
};

export default Footer;
