import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import MyNavbar from "./components/Navbar";
import Hero from "./components/Hero";
import SectionOne from "./components/SectionOne";
import Footer from "./components/Footer";
import SectionTwo from "./components/SectionTwo";
import SectionThree from "./components/SectionThree";
import SectionFour from "./components/SectionFour";
import SectionFive from "./components/SectionFive";
import SectionSix from "./components/SectionSix";
import SectionSeven from "./components/SectionSeven";
import SectionEight from "./components/SectionEight";
import SectionNine from "./components/SectionNine";
import ContactUs from "./components/ContactUs";

const App = () => {
  return (
    <div className="w-100 overflow-x-hidden">
      <Router>
        <>
          <MyNavbar />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <SectionOne />
                  <SectionTwo />
                  <SectionThree />
                  <SectionFour />
                  <SectionFive />
                  <SectionSix />
                  <SectionSeven />
                  <SectionEight />
                  <SectionNine />
                </>
              }
            />
            <Route path="/contact" element={<ContactUs />} />
            {/* Define other routes as needed */}
          </Routes>
          <Footer />
        </>
      </Router>
    </div>
  );
};

export default App;
