import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import AnimationImage from "../assets/img/Big-planet_transparent gif.gif";
import StarIcon from "../assets/img/star-1-9.svg";
import DotsImage from "../assets/img/dots-5.svg";

const SectionEight = () => {
  return (
    <section className="enterprise-messaging-section-eight">
      <Container>
        <Row className="justify-content-center">
          <Col md={6} className="carousal-item-spacing">
            <div className="text-left mt-5 mt-5-why-us">
              <ul className="list-unstyled" >
                <li className="mb-4">
                  <Image src={StarIcon} alt="Star Icon" fluid />
                  <p>
                    Proven experience in managing firewalls by implementing the
                    latest technologies including Penetration Testing, Rules
                    Analysis and Optimization, Improving Blocking Mechanisms,
                    Minimizing Subscribers complaints, Grey Route Detection,
                    Smart Content Filtering, Protection against SPAM and FRAUD.
                  </p>
                </li>
                <li className="mb-4">
                  <Image src={StarIcon} alt="Star Icon" fluid />
                  <p>
                    Holistic approach to SMS monetization by providing traffic
                    analysis, brand trend analysis, brand signature analysis and
                    content analysis as Digi-Touch has end to end visibility
                    from the source of origination at the point of aggregation
                    to the termination point of a mobile operator.
                  </p>
                </li>
                <li className="mb-4">
                  <Image src={StarIcon} alt="Star Icon" fluid />
                  <p>
                    Experience and expertise put into our network protection
                    program that complies fully with all the standards defined
                    by GSMA
                  </p>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <Image
                src={AnimationImage}
                alt="Enterprise SMS"
                className=""
                fluid
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="dots-image-container">
        <div id="products" className="dot-background">

        </div>
      </div>
    </section>
  );
};

export default SectionEight;
