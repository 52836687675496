import { useState, React } from "react";
import { NavDropdown, Navbar, Nav, Container, Button } from "react-bootstrap";
import Logo from "../assets/img/logo.png";
import "./MyNavbar.css"; // Import the custom CSS file
import { useNavigate } from "react-router-dom";

const MyNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(null);
  const navigate = useNavigate(); // For navigation

  // Define handleNavClick function
  const handleNavClick = (sectionId) => {
    navigate("/");
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0); // delay to ensure navigation happens before scrolling
  };

  return (
    <Navbar expand="lg" fixed="top" className="navigation-bar">
      <Container>
        <Navbar.Brand
          onClick={() => handleNavClick("home")}
          className="d-flex align-items-center"
        >
          <img
            src={Logo}
            alt="Digi-Touch Logo"
            width={61}
            className="brand-logo"
          />
          <span className="fs-4 text-white ms-2">Digi-Touch</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto navigation-bar-2 pb-lg-0 pb-4">
            <Nav.Link
              onClick={() => handleNavClick("about-us")}
              className="text-center"
            >
              About
            </Nav.Link>

            <NavDropdown
              title="Services"
              id="services-dropdown"
              className="text-center"
              show={showDropdown === "services"}
              onMouseEnter={() => setShowDropdown("services")}
              onMouseLeave={() => setShowDropdown(null)}
            >
              <NavDropdown.Item onClick={() => handleNavClick("services")}>
                Enterprise Messaging
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavClick("services")}>
                Wholesale A2P Messaging
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavClick("services")}>
                Managed Services
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="Products"
              id="products-dropdown"
              className="text-center"
              show={showDropdown === "products"}
              onMouseEnter={() => setShowDropdown("products")}
              onMouseLeave={() => setShowDropdown(null)}
            >
              <NavDropdown.Item onClick={() => handleNavClick("products")}>
                SMS Firewall
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavClick("products")}>
                Flash Call Firewall
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavClick("products")}>
                Network Probe Testing
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNavClick("products")}>
                MNP
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              onClick={() => handleNavClick("whyus")}
              className="text-center"
            >
              Why Us
            </Nav.Link>
          </Nav>

          <Nav className="contact-us-header">
            <Button
              className="navbar-contact-us-btn"
              onClick={() => navigate("/contact")}
            >
              Contact Us
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
