import React from "react";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const SectionOne = () => {
  const navigate = useNavigate();
  return (
    <div className="div-margin" >
      <div className="mb-5 pb-5 overflow-hidden">
        <Col lg={7} className="mx-auto text-center overflow-hidden">
          <p className="mx-2 px-2 mb-5 display-5 fw-normal">
            Communication <strong>Services</strong> to <br /> Elevate Business
            Performance
          </p>
          <Button onClick={() => navigate('/contact')} className="gradient-border-button-learn">Learn More</Button>
        </Col>
      </div>
    </div>
  );
};

export default SectionOne;
