import React from "react";
import { Button, Container, Image } from "react-bootstrap";
import CareerImage from "../assets/img/Rocket-transparent-gif.gif";
import DotsImage from "../assets/img/dots-5.svg";
import { useNavigate } from 'react-router-dom';

const SectionNine = () => {
    const navigate = useNavigate();
  return (
    <div id='careers' className="overflow-hidden">
      <Container className=" position-relative mb-5">
        <Image
          src={CareerImage}
          width={450}
          alt="Enterprise SMS"
          className="move-left"
          fluid
        />
        <div className=" position-absolute">
          <h1 className=" fw-bolder">Careers</h1>
          <p className="mb-5 fw-normal">
            We’re looking for passionate professionals to join our global team
          </p>
          <Button onClick={() => navigate('/contact')}  className="gradient-border-button-career">Learn More</Button>
        </div>
      </Container>
        <div className="dots-image-container position-relative">
            <div id="products" className="dot-background">

            </div>
        </div>
    </div>
  );
};

export default SectionNine;
