import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import '../assets/Counter.css';

const CounterSection = () => {
    const [startCounter, setStartCounter] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setStartCounter(true);
                    observer.unobserve(counterRef.current); // Unobserve after the counter starts
                }
            },
            { threshold: 0.5 } // Trigger when 50% of the section is visible
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    return (
        <section id="counter" className="counter" ref={counterRef}>
            <div className="main_counter_area">
                <div className="overlay p-y-3">
                    <div className="container">
                        <div className="row">
                            <div className="main_counter_content text-center white-text wow fadeInUp">
                                <div className="col-md-4">
                                    <div className="single_counter p-y-3 m-t-1">
                                        <p>Messages Delivered Per Month</p>
                                        <i className="fa fa-briefcase m-b-1"></i>
                                        <h3 style={{'fontWeight':'bold'}} className="statistic-counter">
                                            {startCounter && <CountUp start={0} end={50} duration={10}/>} Million
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="single_counter p-y-3 m-t-1">
                                        <p>Global Partners</p>
                                        <i className="fa fa-check m-b-1"></i>
                                        <h3 style={{'fontWeight':'bold'}} className="statistic-counter">
                                            {startCounter && <CountUp start={0} end={100} duration={5}/>}+
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="single_counter p-y-3 m-t-1">
                                        <p>Established</p>
                                        <i className="fa fa-coffee m-b-1"></i>
                                        <h3 style={{'fontWeight':'bold'}} className="statistic-counter">
                                            {startCounter && <CountUp start={0} end={2018} duration={5} separator=""/>}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CounterSection;
