import React from "react";
import {Button, Col} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';

const SectionThree = () => {
    const navigate = useNavigate();
    return (

        <div  className="mb-5 pb-5 overflow-hidden">
            <div id="products" className="dot-background">

            </div>
            <Col lg={7} className="mx-auto text-center overflow-hidden" style={{'margin-top' : '7%'}}>
                <p className="mx-2 px-2 mb-5 display-5 fw-normal">
                    Innovative <strong>Products</strong> <br /> Designed for Our Clients
                </p>
                <Button onClick={() => navigate('/contact')} className="gradient-border-button-learn">Learn
                    More</Button>
            </Col>
        </div>
    );
};

export default SectionThree;
