import React from "react";
import {Container, Row, Col, Button, Image, Carousel} from "react-bootstrap";
import ManagedServicesImage from "../assets/img/Managed-Services.svg";
import WholesaleSMSImage from "../assets/img/Wholesale-SMS.svg";
import EnterpriseSMSImage from "../assets/img/Enterprise-SMS.svg";
import StarIcon from "../assets/img/star-1-9.svg";
import DotsImage from "../assets/img/dots-5.svg";
import {useNavigate} from 'react-router-dom';
// import '../index.css'

const SectionTwo = () => {
    const navigate = useNavigate();
    return (
        <section className="enterprise-messaging-section">
            <Container>
                <Carousel variant="dark" controls={false} style={{padding: "40px"}} interval={2000} >
                    <Carousel.Item>
                        <Row className="justify-content-center">
                            <Col md={6} className="carousal-item-spacing">
                                <div className="services-tag">
                                    <h3 className="gradient-text">SERVICES</h3>{" "}
                                </div>
                                <div className="text-left mt-5">
                                    <h2 className="enterprise-messaging-title">
                                        <strong>Enterprise</strong> Messaging
                                    </h2>
                                    <ul className="list-unstyled">
                                        <li className="mb-4">
                                            <Image src={StarIcon} alt="Star Icon" fluid/>
                                            <p>
                                                With more than 6 billion users, SMS can be used for
                                                transactions, security alerts, promotions, and
                                                operational notifications.
                                            </p>
                                        </li>
                                        <li className="mb-4">
                                            <Image src={StarIcon} alt="Star Icon" fluid/>
                                            <p>
                                                Scheduled and automated messaging drives marketing and
                                                engagement initiatives to your customer base.
                                            </p>
                                        </li>

                                    </ul>
                                    <Button className="gradient-border-button-carousal"
                                            onClick={() => navigate('/contact')}>
                                        Learn More
                                    </Button>
                                </div>
                            </Col>
                            <Col
                                md={6}
                                className=" justify-content-center align-items-center"
                            >
                                <div className="image-stack">
                                    <Image
                                        src={EnterpriseSMSImage}
                                        alt="Enterprise SMS"
                                        className="image-top"
                                        width={220}
                                        fluid
                                    />
                                    <Image
                                        src={ManagedServicesImage}
                                        alt="Managed Services"
                                        className="image-middle"
                                        width={100}
                                        fluid
                                    />
                                    <Image
                                        src={WholesaleSMSImage}
                                        alt="Wholesale SMS"
                                        className="image-bottom"
                                        fluid
                                        width={100}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Carousel.Item>

                    <Carousel.Item>
                        <Row className="justify-content-center">
                            <Col md={6} className="carousal-item-spacing">
                                <div className="services-tag">
                                    <h3 className="gradient-text">SERVICES</h3>{" "}
                                </div>
                                <div className="text-left mt-5">
                                    <h2 className="enterprise-messaging-title">
                                        <strong>Wholesale A2P</strong> Messaging
                                    </h2>
                                    <ul className="list-unstyled">
                                        <li className="mb-4">
                                            <Image src={StarIcon} alt="Star Icon" fluid/>
                                            <p>
                                                Leverage our global reach to deliver high-quality A2P
                                                messaging to every corner of the world.
                                            </p>
                                        </li>
                                        <li className="mb-4">
                                            <Image src={StarIcon} alt="Star Icon" fluid/>
                                            <p>
                                                Secure and reliable connections ensure your messages
                                                reach their destination promptly.
                                            </p>
                                        </li>

                                    </ul>
                                    <Button className="gradient-border-button-carousal"
                                            onClick={() => navigate('/contact')}>
                                        Learn More
                                    </Button>
                                </div>
                            </Col>
                            <Col
                                md={6}
                                className=" justify-content-center align-items-center"
                            >
                                <div className="image-stack">
                                    <Image
                                        src={EnterpriseSMSImage}
                                        alt="Enterprise SMS"
                                        className="image-bottom"
                                        width={100}
                                        fluid
                                    />
                                    <Image
                                        src={ManagedServicesImage}
                                        alt="Managed Services"
                                        className="image-middle"
                                        width={100}
                                        fluid
                                    />
                                    <Image
                                        src={WholesaleSMSImage}
                                        alt="Wholesale SMS"
                                        className="image-top"
                                        fluid
                                        width={220}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Carousel.Item>

                    <Carousel.Item>
                        <Row className="justify-content-center">
                            <Col md={6} className="carousal-item-spacing">
                                <div className="services-tag">
                                    <h3 className="gradient-text">SERVICES</h3>{" "}
                                </div>
                                <div className="text-left mt-5">
                                    <h2 className="enterprise-messaging-title">
                                        <strong>Managed</strong> Services
                                    </h2>
                                    <ul className="list-unstyled">
                                        <li className="mb-4">
                                            <Image src={StarIcon} alt="Star Icon" fluid/>
                                            <p>
                                                We manage the entire messaging process, ensuring smooth
                                                operations and optimal performance.
                                            </p>
                                        </li>
                                        <li className="mb-4">
                                            <Image src={StarIcon} alt="Star Icon" fluid/>
                                            <p>
                                                Focus on your business while we handle the complexities
                                                of message delivery.
                                            </p>
                                        </li>
                                    </ul>
                                    <Button className="gradient-border-button-carousal"
                                            onClick={() => navigate('/contact')}>
                                        Learn More
                                    </Button>
                                </div>
                            </Col>
                            <Col
                                md={6}
                                className=" justify-content-center align-items-center"
                            >
                                <div className="image-stack">
                                    <Image
                                        src={EnterpriseSMSImage}
                                        alt="Enterprise SMS"
                                        className="image-middle"
                                        width={100}
                                        fluid
                                    />
                                    <Image
                                        src={ManagedServicesImage}
                                        alt="Managed Services"
                                        className="image-top"
                                        width={220}
                                        fluid
                                    />
                                    <Image
                                        src={WholesaleSMSImage}
                                        alt="Wholesale SMS"
                                        className="image-bottom"
                                        width={100}
                                        fluid
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Carousel.Item>
                </Carousel>
            </Container>

        </section>
    );
};

export default SectionTwo;
