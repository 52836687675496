import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import DotsImage from "../assets/img/dots-1.svg";
import SmsApisImage from "../assets/img/group-21.png";
import ReportingImage from "../assets/img/group-427318293.png";
import WebPortalImage from "../assets/img/group-23.png";
import SmppImage from "../assets/img/group-25.png";
import PredefinedRoutingImage from "../assets/img/group-24.png";
import DeliveryImage from "../assets/img/group-26.png";

const SectionSix = () => {
  return (
    <section className="sx-section-six">
      <Container>
        <Row className="sx-overlap">
          <Col xs={12} md={4} className="mb-4">
            <div className="sx-frame">
              <Image className="sx-img" src={SmsApisImage} fluid />
              <div className="sx-text-wrapper">Our SMS APIs</div>
              <p className="sx-p">
                Integrate SMS APIs for instant outbound, inbound, and two-way
                messaging in just minutes.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <div className="sx-frame">
              <Image className="sx-img" src={ReportingImage} fluid />
              <div className="sx-text-wrapper">Reporting</div>
              <p className="sx-p">
                We ensure you know the outcome of every message. With delivery
                reports and handset acknowledgments, you can analyze data
                quality and cut costs by removing inactive numbers.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <div className="sx-frame">
              <Image className="sx-img" src={WebPortalImage} fluid />
              <div className="sx-text-wrapper">Web Portal</div>
              <p className="sx-p">
                Retail market senders can compile messages in a CSV file format
                and send in just one click.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <div className="sx-frame">
              <Image className="sx-img" src={SmppImage} fluid />
              <div className="sx-text-wrapper">SMPP</div>
              <p className="sx-p">
                SMS aggregators and network operators can join us over the
                industry standard protocol, SMPP.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <div className="sx-frame">
              <Image className="sx-img" src={PredefinedRoutingImage} fluid />
              <div className="sx-text-wrapper">Pre-defined Routing</div>
              <p className="sx-p">
                Whether it's service delivery in a few seconds or scheduling the
                targets, we adapt our routing to suit you. Need a branded sender
                ID? Want to pay on delivered and not submitted? We have options.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <div className="sx-frame">
              <Image className="sx-img" src={DeliveryImage} fluid />
              <div className="sx-text-wrapper">Delivery</div>
              <p className="sx-p">
                Priority-based routing ensures mission-critical SMS delivery
                first. Multiple priority levels let you fast-track your queue,
                ideal for concurrent marketing campaigns and time-sensitive
                notifications via one account.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="sx-dots-image-container">
        <div id="whyus" className="dot-background">

        </div>
      </div>
    </section>
  );
};

export default SectionSix;
