import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import DotsImage from "../assets/img/dots-5.svg";
import FirewallImage from "../assets/img/group-427318291.png";
import FlashCallFirewallImage from "../assets/img/group-427318290.png";
import NetworkProbeImage from "../assets/img/group-427318292.png";
import MNPImage from "../assets/img/group-81.png";

const SectionFour = () => {
  return (
    <section className="sf-section-four">
      <Container>
        <Row className="sf-overlap">
          <Col xs={12} md={6} className="mb-4">
            <div className="sf-frame">
              <Image className="sf-img" src={FirewallImage} fluid />
              <div className="sf-text-wrapper">SMS Firewall</div>
              <p className="sf-p">
                Digi-Touch is a reliable partner for mobile operators with
                proven solutions for A2P monetization &amp; enablement as well
                as Firewall services. Digi-Touch takes pride in our agnostic
                approach to Firewall Technology partners as we focus on
                providing a state-of-the-art customer journey to the operator
                independent on choice of technology vendor.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <div className="sf-frame">
              <Image className="sf-img" src={FlashCallFirewallImage} fluid />
              <div className="sf-text-wrapper">Flash Call Firewall</div>
              <p className="sf-p">
                Provides complete visibility of A2P voice traffic for operators,
                allowing them to establish a monetization model and secure their
                revenues in both A2P SMS and A2P Voice.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <div className="sf-frame-2">
              <Image className="sf-group-2" src={NetworkProbeImage} fluid />
              <div className="sf-text-wrapper">Network Probe Testing</div>
              <p className="sf-p">
                Digi-Touch is continuously developing automated processes to
                conduct undetected network vulnerability tests on real devices,
                avoiding commonly known testing probes that can be whitelisted.
                By performing incognito penetration testing, Digi-Touch monitors
                international A2P SMS delivery into mobile networks, identifying
                potential revenue leakage.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <div className="sf-frame-2">
              <Image className="sf-group-2" src={MNPImage} fluid />
              <div className="sf-text-wrapper">Mobile Number Portability</div>
              <p className="sf-p">
                With our MNP service, enterprises can ensure a 100% SMS delivery
                rate, eliminate message failures, and provide an exceptional
                customer experience.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="sf-dots-image-container">
        <div id="products" className="dot-background">

        </div>
      </div>
    </section>
  );
};

export default SectionFour;
